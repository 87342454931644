import { Campaign } from "./Campaign";
import { Employee } from "./Employee";
import { Machine } from "./Machine";
import { MachineCounterType } from "./MachineCounterTracking";
import { OperatingUnit } from "./OperatingUnit";
import { Order } from "./Order";
import { OrderStatus } from "./OrderStatus";
import { Project } from "./Project";
import { AnyPriceStructure } from "./services/ServicePriceBlocks";
import { computeDates } from "../utils/computeDates";
import { generateSearchableSubstrings } from "../utils/generateSearchableSubstrings";

export type RentalOrderStatus =
    | OrderStatus.DRAFT
    | OrderStatus.IN_PROGRESS
    | OrderStatus.DONE
    | OrderStatus.CHECKED
    | OrderStatus.BILLED;

export class RentalOrder {
    public id: string;
    public orderNumber: number | null;
    public name: string;
    public status: RentalOrderStatus;
    public billId: string | null;
    public deliveryNoteId: string | null;
    public plannedStartDateTime: string | null;
    public plannedEndDateTime: string | null;
    /**
     * Only used for querying. Automatically set by firestore triggered function.
     */
    public readonly plannedDates: string[];
    public customerId: string;
    public machineIds: string[];
    public notes: string | null;
    public machinePriceTrackings: AnyRentalOrderPriceTracking[];
    public archived: boolean;
    public operatingUnitId: OperatingUnit["id"] | null;
    /**
     * only for use in queries, set via triggers
     */
    public _searchableSubstrings: string[];

    public campaignId: Campaign["id"] | null;
    public projectId: Project["id"] | null;
    public creatorId: Employee["id"] | null;

    constructor(initialValues?: Partial<RentalOrder>) {
        this.id = initialValues?.id ?? "";
        this.orderNumber = initialValues?.orderNumber ?? null;
        this.name = initialValues?.name ?? `Vermietungsauftrag ${initialValues?.id}`;
        this.status = initialValues?.status ?? OrderStatus.DRAFT;
        this.billId = initialValues?.billId ?? null;
        this.deliveryNoteId = initialValues?.deliveryNoteId ?? null;
        this.plannedStartDateTime = initialValues?.plannedStartDateTime ?? null;
        this.plannedEndDateTime = initialValues?.plannedEndDateTime ?? null;
        this.plannedDates =
            initialValues?.plannedDates ??
            computeDates(initialValues?.plannedStartDateTime ?? null, initialValues?.plannedEndDateTime ?? null);
        this.customerId = initialValues?.customerId ?? "";
        this.machineIds = initialValues?.machineIds ?? [];
        this.notes = initialValues?.notes ?? null;
        this.archived = initialValues?.archived ?? false;
        this.operatingUnitId = initialValues?.operatingUnitId ?? null;
        this.machinePriceTrackings = initialValues?.machinePriceTrackings ?? [];
        this._searchableSubstrings =
            (initialValues as { _searchableSubstrings?: string[] })?._searchableSubstrings ?? [];
        this.campaignId = initialValues?.campaignId ?? null;
        this.projectId = initialValues?.projectId ?? null;
        this.creatorId = initialValues?.creatorId ?? null;
    }
}

export enum RentalOrderPriceTrackingType {
    MACHINE_COUNTER = "MACHINE_COUNTER",
    CUSTOM_COUNTER = "CUSTOM_COUNTER",
}

export type RentalOrderPriceTracking = {
    id: string;
    type: RentalOrderPriceTrackingType;
    machineId: Machine["id"];
    price: AnyPriceStructure;
    maschinenring: {
        vskz: string | null;
        fuelTarget: boolean;
    };
};

export type RentalOrderMachineCounterPriceTracking = RentalOrderPriceTracking & {
    type: RentalOrderPriceTrackingType.MACHINE_COUNTER;
    machineCounterType: MachineCounterType;
    startEmployeeId: Employee["id"] | null;
    /**
     * ISO datetime
     */
    startDateTime: string | null;
    startValue: number | null;
    endEmployeeId: Employee["id"] | null;
    /**
     * ISO datetime
     */
    endDateTime: string | null;
    endValue: number | null;
};

export type RentalOrderCustomCounterPriceTracking = RentalOrderPriceTracking & {
    type: RentalOrderPriceTrackingType.CUSTOM_COUNTER;
    unit: string;
    employeeId: Employee["id"] | null;
    value: number | null;
};

export type AnyRentalOrderPriceTracking =
    | RentalOrderMachineCounterPriceTracking
    | RentalOrderCustomCounterPriceTracking;

export function isRentalOrder(order: Order | RentalOrder): order is RentalOrder {
    const cast = order as RentalOrder;
    return cast.machinePriceTrackings !== undefined;
}

export function getRentalOrderName(customerName?: string) {
    return `Vermietung ${customerName ? `(bei ${customerName})` : ""}`;
}

export function generateSearchableSubstringsForRentalOrder(rentalOrder: Partial<RentalOrder>) {
    const searchableCustomerAttributes: string[] = [
        rentalOrder.name?.substring(0, 100),
        rentalOrder.orderNumber?.toString(),
    ].filter(Boolean) as string[];

    return generateSearchableSubstrings(searchableCustomerAttributes);
}
